.authentification {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7), hsl(0, 0%, 0%)), url(http://palais-culture-tlemcen.org/images/photo01.bmp);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  /*  background-position: top center;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  display: flex;
  position: relative;*/
  padding: 40px 0;

  h2 {
    text-align: center;
    margin: 40px;
    font-weight: bold;
    color: #ffffff;
    font-size: 24px;
  }

  h4 {
    font-size: 16px;
    margin: 0;
    margin-bottom: 10px;
  }

  .auth-form {
    width: 65%;
    margin: auto;
    background: #ffffff;
    padding: 35px 35px;
    border-radius: 8px;
    margin-top: 30px;
    h1 {
      text-align: left;
      margin: 0;
      font-weight: bold;
      color: #080706;
      margin-bottom: 35px;
    }

    .form-label {
      //! Reset label input
      color: #0e0e0e;
      font-weight: 600;
    }

    .form-control {
      //! Reset Input
      border: none;
      border-bottom: 1px solid #a9a9a9;
      border-radius: 0;
      &:focus {
        box-shadow: none;
        border-bottom-color: #080706;
      }
    }

    .form-control-error {
      border: 1px solid #e72222;
      border-bottom: 3px solid #e72222 !important;
    }
    .error-text {
      color: #e72222;
      font-size: 14px;
      margin-top: 8px;
    }
  }
}

/* Responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 662px) {
  .auth-form {
    width: 100% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 662px) and (max-width: 768px) {
  /* Global */
  .auth-form {
    width: 100% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .auth-form {
    width: 100% !important;
  }
}
